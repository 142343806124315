

import Vue from 'vue';
import axios from 'axios';
import Router from '../router/index';
import store from '../store';
import { geolocation } from './index';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

const config = {
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : '',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    config.headers.r = new Date().getTime();
    config.headers.accessToken = store.state.accessToken;
    config.headers.source = 'H5';
    const channel = Router.history.current.query.channel || localStorage.channel;
    if (channel) {
      localStorage.channel = channel;
      config.headers.channel = channel;
    }
    config.headers.gps = await geolocation();
    config.headers.merchantId = 'b5a84f164746cfd448b144f543e22809';
    config.headers.inputChannel = 'AJEHOME';
    config.headers.versionId = '20220909';
    return config;
  },
  error =>
    // Do something with request error
    Promise.reject(error),

);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    try {
      if (response.data.status.code === '997') {
        store.commit('SET_ACCESS_TOKEN');
        store.commit('SET_USER', {});
      }
    } catch (e) {}
    return response.data;
  },
  (error) => {
    const data = error.data ? error.data : (error.request.response && JSON.parse(error.request.response));
    // Do something with response error
    return Promise.reject(data);
  },

);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
