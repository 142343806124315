import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: '',
    user: {},
  },
  mutations: {
    SET_ACCESS_TOKEN(state, val) {
      state.accessToken = val;
    },
    SET_USER(state, val) {
      state.user = val;
    },
  },
  actions: {
  },
  modules: {

  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        accessToken: val.accessToken,
      };
    },
  }), createPersistedState({
    storage: window.sessionStorage,
    reducer(val) {
      return {};
    },
  })],
});
