import Vue from 'vue';
import './plugins/axios';
import VueI18n from 'vue-i18n';
import InputFacade from 'vue-input-facade';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element';
import enLocale from './lang/en.json';

const options = {
  name: 'mask',
  tokens: {
    '#': { pattern: /\d/ },
    A: { pattern: /[A-Za-z]/i },
    T: { pattern: /[0-9A-Za-z]/i },
    X: { pattern: /./ },
  },
};
Vue.use(InputFacade, options);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en-US', // 语言标识
  // this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'en-US': enLocale,
  },
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
